<template>
  <div name="Caselist">
    <div class="top">
    <div class="topMain">
    <div class="logo"> </div>
    <div class="topRig"> </div>
  </div>
  </div>
  <div class="wbg">
      <div class="wrapper">
        <div class="clearfix">
          <div class="sidebar">
            <div class="sdbox">
              <h4><span class="sdtit01">检索导航-案例类型</span></h4>
              <div style="position:relative">
                <ul>
                  <Collection  name="recase_type"  v-slot="{ collection }"
                  :query="{  }">
                  <li v-for="value in collection" :key="value.item_value" :class="`casetype box`+value.item_value"><a-icon type="folder-open" /><router-link :to="`/case/list/${value.item_value}`">{{value.item_name}}</router-link></li>
                  </Collection>
                </ul>
              </div>
            </div>
          </div>
          <div class="content">
            <Collection  name="case"
            :limit="provider.defaultPageSize"
            :page="provider.current"
            v-slot="{ collection, count  }"
            :query="{ recase_title : recase_title, recase_type: id }">
          <div class="myCity">
              <h4> 案例展示</h4>
            </div>
            <div class="column">
              <div id="newestcaseDiv" class="p-4">
              <div id="searchResultDiv">
                <div class="subtitlesear">
    <a-input-search
                        style="width: 700px"
                        placeholder="请输入关键字搜索"
                        enter-button="搜索"
                        size="large"
                        @search="
                          (value) => (recase_title = value)
                        "
                      />
    </div>
                <div class="sorttit">
                  <div class="" style="float: left; margin-left: 20px;"></div>
                </div>
              </div>
            </div>
            </div>
            <div class="sortlist mb-4">
                <table width="100%" border="0">
                      <thead>
                        <tr class="sortlisttop">
                          <td style="text-align: center" width="80%">案例标题</td>
                          <td style="text-align: center" width="10%">关键词</td>
                          <td style="text-align: center" width="10%">地域</td>
                        </tr>
                  </thead>
                  <tbody class="caseManage">
                    <tr  v-for="caseinfo in collection" :key="caseinfo.recase_id">
                       <td><a-icon type="file" /><router-link :to="`/case/detail/${caseinfo.recase_id}`">{{caseinfo.recase_title}}</router-link></td>
                       <td class="tc">{{caseinfo.recase_keyword}}</td>
                       <td class="tc">{{caseinfo.recase_area}}</td>
                  </tr>
                  </tbody>
                </table>
            </div>
            <div class="tabs_pagination">
              <a-pagination
                  show-quick-jumper
                  :current="provider.current"
                  :defaultPageSize="provider.defaultPageSize"
                  :total="count"
                  @change="onChange"
                />
          </div>
           </Collection>
          </div>
        </div>
      </div>
  </div>
  </div>
</template>
<script>
import Collection from '@/components/Collection'
export default {
  name: 'Expert',
  components: {
    Collection
  },
  data () {
    return {
      id: this.$route.params.id,
      recase_title: '',
      provider: {
        current: 1,
        defaultPageSize: 12,
        limit: 1
      }
    }
  },
  methods: {
    onChange (page, pageSize) {
      this.provider.current = this.provider.limit = page
    }
  },
  activated () {
    this.id = this.$route.params.id
  },
  watch: {
    '$route.params.id': function (id) {
      this.id = id
    }
  }
}
</script>
<style scoped src="../../assets/css/case.css"></style>
